exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-index-jsx": () => import("./../../../src/pages/about-us/index.jsx" /* webpackChunkName: "component---src-pages-about-us-index-jsx" */),
  "component---src-pages-audit-demo-index-jsx": () => import("./../../../src/pages/audit-demo/index.jsx" /* webpackChunkName: "component---src-pages-audit-demo-index-jsx" */),
  "component---src-pages-audit-index-jsx": () => import("./../../../src/pages/audit/index.jsx" /* webpackChunkName: "component---src-pages-audit-index-jsx" */),
  "component---src-pages-book-demo-audit-jsx": () => import("./../../../src/pages/book-demo/audit.jsx" /* webpackChunkName: "component---src-pages-book-demo-audit-jsx" */),
  "component---src-pages-book-demo-index-jsx": () => import("./../../../src/pages/book-demo/index.jsx" /* webpackChunkName: "component---src-pages-book-demo-index-jsx" */),
  "component---src-pages-calculator-index-jsx": () => import("./../../../src/pages/calculator/index.jsx" /* webpackChunkName: "component---src-pages-calculator-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-newsroom-index-jsx": () => import("./../../../src/pages/newsroom/index.jsx" /* webpackChunkName: "component---src-pages-newsroom-index-jsx" */),
  "component---src-pages-partners-index-jsx": () => import("./../../../src/pages/partners/index.jsx" /* webpackChunkName: "component---src-pages-partners-index-jsx" */),
  "component---src-pages-privacy-policy-index-jsx": () => import("./../../../src/pages/privacy-policy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-jsx" */),
  "component---src-pages-product-index-jsx": () => import("./../../../src/pages/product/index.jsx" /* webpackChunkName: "component---src-pages-product-index-jsx" */),
  "component---src-pages-supercharged-block-storage-js": () => import("./../../../src/pages/supercharged-block-storage.js" /* webpackChunkName: "component---src-pages-supercharged-block-storage-js" */),
  "component---src-pages-terms-and-conditions-index-jsx": () => import("./../../../src/pages/terms-and-conditions/index.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-jsx" */),
  "component---src-pages-testimonials-index-jsx": () => import("./../../../src/pages/testimonials/index.jsx" /* webpackChunkName: "component---src-pages-testimonials-index-jsx" */)
}

